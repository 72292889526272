"use strict";
// Copyright 2021-2024 Buf Technologies, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListValue = exports.Value = exports.Struct = exports.NullValue = void 0;
// @generated by protoc-gen-es v1.7.2 with parameter "bootstrap_wkt=true,ts_nocheck=false,target=ts"
// @generated from file google/protobuf/struct.proto (package google.protobuf, syntax proto3)
/* eslint-disable */
const proto3_js_1 = require("../../proto3.js");
const message_js_1 = require("../../message.js");
/**
 * `NullValue` is a singleton enumeration to represent the null value for the
 * `Value` type union.
 *
 * The JSON representation for `NullValue` is JSON `null`.
 *
 * @generated from enum google.protobuf.NullValue
 */
var NullValue;
(function (NullValue) {
    /**
     * Null value.
     *
     * @generated from enum value: NULL_VALUE = 0;
     */
    NullValue[NullValue["NULL_VALUE"] = 0] = "NULL_VALUE";
})(NullValue || (exports.NullValue = NullValue = {}));
// Retrieve enum metadata with: proto3.getEnumType(NullValue)
proto3_js_1.proto3.util.setEnumType(NullValue, "google.protobuf.NullValue", [
    { no: 0, name: "NULL_VALUE" },
]);
/**
 * `Struct` represents a structured data value, consisting of fields
 * which map to dynamically typed values. In some languages, `Struct`
 * might be supported by a native representation. For example, in
 * scripting languages like JS a struct is represented as an
 * object. The details of that representation are described together
 * with the proto support for the language.
 *
 * The JSON representation for `Struct` is JSON object.
 *
 * @generated from message google.protobuf.Struct
 */
class Struct extends message_js_1.Message {
    constructor(data) {
        super();
        /**
         * Unordered map of dynamically typed values.
         *
         * @generated from field: map<string, google.protobuf.Value> fields = 1;
         */
        this.fields = {};
        proto3_js_1.proto3.util.initPartial(data, this);
    }
    toJson(options) {
        const json = {};
        for (const [k, v] of Object.entries(this.fields)) {
            json[k] = v.toJson(options);
        }
        return json;
    }
    fromJson(json, options) {
        if (typeof json != "object" || json == null || Array.isArray(json)) {
            throw new Error("cannot decode google.protobuf.Struct from JSON " + proto3_js_1.proto3.json.debug(json));
        }
        for (const [k, v] of Object.entries(json)) {
            this.fields[k] = Value.fromJson(v);
        }
        return this;
    }
    static fromBinary(bytes, options) {
        return new Struct().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Struct().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Struct().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3_js_1.proto3.util.equals(Struct, a, b);
    }
}
exports.Struct = Struct;
Struct.runtime = proto3_js_1.proto3;
Struct.typeName = "google.protobuf.Struct";
Struct.fields = proto3_js_1.proto3.util.newFieldList(() => [
    { no: 1, name: "fields", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: Value } },
]);
/**
 * `Value` represents a dynamically typed value which can be either
 * null, a number, a string, a boolean, a recursive struct value, or a
 * list of values. A producer of value is expected to set one of these
 * variants. Absence of any variant indicates an error.
 *
 * The JSON representation for `Value` is JSON value.
 *
 * @generated from message google.protobuf.Value
 */
class Value extends message_js_1.Message {
    constructor(data) {
        super();
        /**
         * The kind of value.
         *
         * @generated from oneof google.protobuf.Value.kind
         */
        this.kind = { case: undefined };
        proto3_js_1.proto3.util.initPartial(data, this);
    }
    toJson(options) {
        switch (this.kind.case) {
            case "nullValue":
                return null;
            case "numberValue":
                if (!Number.isFinite(this.kind.value)) {
                    throw new Error("google.protobuf.Value cannot be NaN or Infinity");
                }
                return this.kind.value;
            case "boolValue":
                return this.kind.value;
            case "stringValue":
                return this.kind.value;
            case "structValue":
            case "listValue":
                return this.kind.value.toJson(Object.assign(Object.assign({}, options), { emitDefaultValues: true }));
        }
        throw new Error("google.protobuf.Value must have a value");
    }
    fromJson(json, options) {
        switch (typeof json) {
            case "number":
                this.kind = { case: "numberValue", value: json };
                break;
            case "string":
                this.kind = { case: "stringValue", value: json };
                break;
            case "boolean":
                this.kind = { case: "boolValue", value: json };
                break;
            case "object":
                if (json === null) {
                    this.kind = { case: "nullValue", value: NullValue.NULL_VALUE };
                }
                else if (Array.isArray(json)) {
                    this.kind = { case: "listValue", value: ListValue.fromJson(json) };
                }
                else {
                    this.kind = { case: "structValue", value: Struct.fromJson(json) };
                }
                break;
            default:
                throw new Error("cannot decode google.protobuf.Value from JSON " + proto3_js_1.proto3.json.debug(json));
        }
        return this;
    }
    static fromBinary(bytes, options) {
        return new Value().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Value().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Value().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3_js_1.proto3.util.equals(Value, a, b);
    }
}
exports.Value = Value;
Value.runtime = proto3_js_1.proto3;
Value.typeName = "google.protobuf.Value";
Value.fields = proto3_js_1.proto3.util.newFieldList(() => [
    { no: 1, name: "null_value", kind: "enum", T: proto3_js_1.proto3.getEnumType(NullValue), oneof: "kind" },
    { no: 2, name: "number_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "kind" },
    { no: 3, name: "string_value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "kind" },
    { no: 4, name: "bool_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "kind" },
    { no: 5, name: "struct_value", kind: "message", T: Struct, oneof: "kind" },
    { no: 6, name: "list_value", kind: "message", T: ListValue, oneof: "kind" },
]);
/**
 * `ListValue` is a wrapper around a repeated field of values.
 *
 * The JSON representation for `ListValue` is JSON array.
 *
 * @generated from message google.protobuf.ListValue
 */
class ListValue extends message_js_1.Message {
    constructor(data) {
        super();
        /**
         * Repeated field of dynamically typed values.
         *
         * @generated from field: repeated google.protobuf.Value values = 1;
         */
        this.values = [];
        proto3_js_1.proto3.util.initPartial(data, this);
    }
    toJson(options) {
        return this.values.map(v => v.toJson());
    }
    fromJson(json, options) {
        if (!Array.isArray(json)) {
            throw new Error("cannot decode google.protobuf.ListValue from JSON " + proto3_js_1.proto3.json.debug(json));
        }
        for (let e of json) {
            this.values.push(Value.fromJson(e));
        }
        return this;
    }
    static fromBinary(bytes, options) {
        return new ListValue().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListValue().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListValue().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3_js_1.proto3.util.equals(ListValue, a, b);
    }
}
exports.ListValue = ListValue;
ListValue.runtime = proto3_js_1.proto3;
ListValue.typeName = "google.protobuf.ListValue";
ListValue.fields = proto3_js_1.proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "message", T: Value, repeated: true },
]);
