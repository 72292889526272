"use strict";
// Copyright 2021-2024 The Connect Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendHeaders = exports.decodeBinaryHeader = exports.encodeBinaryHeader = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const connect_error_js_1 = require("./connect-error.js");
const code_js_1 = require("./code.js");
/**
 * Encode a single binary header value according to the Connect
 * and gRPC specifications.
 *
 * This function accepts raw binary data from a buffer, a string
 * with UTF-8 text, or a protobuf message. It encodes the input
 * with unpadded base64 and returns a string that can be used for
 * a header whose name ends with `-bin`.
 */
function encodeBinaryHeader(value) {
    let bytes;
    if (value instanceof protobuf_1.Message) {
        bytes = value.toBinary();
    }
    else if (typeof value == "string") {
        bytes = new TextEncoder().encode(value);
    }
    else {
        bytes = value instanceof Uint8Array ? value : new Uint8Array(value);
    }
    return protobuf_1.protoBase64.enc(bytes).replace(/=+$/, "");
}
exports.encodeBinaryHeader = encodeBinaryHeader;
function decodeBinaryHeader(value, type, options) {
    try {
        const bytes = protobuf_1.protoBase64.dec(value);
        if (type) {
            return type.fromBinary(bytes, options);
        }
        return bytes;
    }
    catch (e) {
        throw connect_error_js_1.ConnectError.from(e, code_js_1.Code.DataLoss);
    }
}
exports.decodeBinaryHeader = decodeBinaryHeader;
/**
 * Merge two or more Headers objects by appending all fields from
 * all inputs to a new Headers object.
 */
function appendHeaders(...headers) {
    const h = new Headers();
    for (const e of headers) {
        e.forEach((value, key) => {
            h.append(key, value);
        });
    }
    return h;
}
exports.appendHeaders = appendHeaders;
