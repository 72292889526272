"use strict";
// Copyright 2021-2024 The Connect Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
Object.defineProperty(exports, "__esModule", { value: true });
exports.headerUserAgent = exports.headerProtocolVersion = exports.headerTimeout = exports.headerStreamAcceptEncoding = exports.headerUnaryAcceptEncoding = exports.headerStreamEncoding = exports.headerUnaryEncoding = exports.headerUnaryContentLength = exports.headerContentType = void 0;
/**
 * @private Internal code, does not follow semantic versioning.
 */
exports.headerContentType = "Content-Type";
exports.headerUnaryContentLength = "Content-Length";
exports.headerUnaryEncoding = "Content-Encoding";
exports.headerStreamEncoding = "Connect-Content-Encoding";
exports.headerUnaryAcceptEncoding = "Accept-Encoding";
exports.headerStreamAcceptEncoding = "Connect-Accept-Encoding";
exports.headerTimeout = "Connect-Timeout-Ms";
exports.headerProtocolVersion = "Connect-Protocol-Version";
exports.headerUserAgent = "User-Agent";
