"use strict";
// Copyright 2021-2024 The Connect Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
Object.defineProperty(exports, "__esModule", { value: true });
exports.headerUserAgent = exports.headerMessageType = exports.headerStatusDetailsBin = exports.headerGrpcMessage = exports.headerGrpcStatus = exports.headerTimeout = exports.headerAcceptEncoding = exports.headerEncoding = exports.headerContentType = void 0;
/**
 * @private Internal code, does not follow semantic versioning.
 */
exports.headerContentType = "Content-Type";
exports.headerEncoding = "Grpc-Encoding";
exports.headerAcceptEncoding = "Grpc-Accept-Encoding";
exports.headerTimeout = "Grpc-Timeout";
exports.headerGrpcStatus = "Grpc-Status";
exports.headerGrpcMessage = "Grpc-Message";
exports.headerStatusDetailsBin = "Grpc-Status-Details-Bin";
exports.headerMessageType = "Grpc-Message-Type";
exports.headerUserAgent = "User-Agent";
