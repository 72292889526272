"use strict";
// Copyright 2021-2024 The Connect Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
Object.defineProperty(exports, "__esModule", { value: true });
exports.createJsonSerialization = exports.createBinarySerialization = exports.limitSerialization = exports.createClientMethodSerializers = exports.createMethodSerializationLookup = exports.getJsonOptions = void 0;
const connect_error_js_1 = require("../connect-error.js");
const code_js_1 = require("../code.js");
const limit_io_js_1 = require("./limit-io.js");
/**
 * Sets default JSON serialization options for connect-es.
 *
 * With standard protobuf JSON serialization, unknown JSON fields are
 * rejected by default. In connect-es, unknown JSON fields are ignored
 * by default.
 */
function getJsonOptions(options) {
    var _a;
    const o = Object.assign({}, options);
    (_a = o.ignoreUnknownFields) !== null && _a !== void 0 ? _a : (o.ignoreUnknownFields = true);
    return o;
}
exports.getJsonOptions = getJsonOptions;
/**
 * Create an object that provides convenient access to request and response
 * message serialization for a given method.
 *
 * @private Internal code, does not follow semantic versioning.
 */
function createMethodSerializationLookup(method, binaryOptions, jsonOptions, limitOptions) {
    const inputBinary = limitSerialization(createBinarySerialization(method.I, binaryOptions), limitOptions);
    const inputJson = limitSerialization(createJsonSerialization(method.I, jsonOptions), limitOptions);
    const outputBinary = limitSerialization(createBinarySerialization(method.O, binaryOptions), limitOptions);
    const outputJson = limitSerialization(createJsonSerialization(method.O, jsonOptions), limitOptions);
    return {
        getI(useBinaryFormat) {
            return useBinaryFormat ? inputBinary : inputJson;
        },
        getO(useBinaryFormat) {
            return useBinaryFormat ? outputBinary : outputJson;
        },
    };
}
exports.createMethodSerializationLookup = createMethodSerializationLookup;
/**
 * Returns functions to normalize and serialize the input message
 * of an RPC, and to parse the output message of an RPC.
 *
 * @private Internal code, does not follow semantic versioning.
 */
function createClientMethodSerializers(method, useBinaryFormat, jsonOptions, binaryOptions) {
    const input = useBinaryFormat
        ? createBinarySerialization(method.I, binaryOptions)
        : createJsonSerialization(method.I, jsonOptions);
    const output = useBinaryFormat
        ? createBinarySerialization(method.O, binaryOptions)
        : createJsonSerialization(method.O, jsonOptions);
    return { parse: output.parse, serialize: input.serialize };
}
exports.createClientMethodSerializers = createClientMethodSerializers;
/**
 * Apply I/O limits to a Serialization object, returning a new object.
 *
 * @private Internal code, does not follow semantic versioning.
 */
function limitSerialization(serialization, limitOptions) {
    return {
        serialize(data) {
            const bytes = serialization.serialize(data);
            (0, limit_io_js_1.assertWriteMaxBytes)(limitOptions.writeMaxBytes, bytes.byteLength);
            return bytes;
        },
        parse(data) {
            (0, limit_io_js_1.assertReadMaxBytes)(limitOptions.readMaxBytes, data.byteLength, true);
            return serialization.parse(data);
        },
    };
}
exports.limitSerialization = limitSerialization;
/**
 * Creates a Serialization object for serializing the given protobuf message
 * with the protobuf binary format.
 */
function createBinarySerialization(messageType, options) {
    return {
        parse(data) {
            try {
                return messageType.fromBinary(data, options);
            }
            catch (e) {
                const m = e instanceof Error ? e.message : String(e);
                throw new connect_error_js_1.ConnectError(`parse binary: ${m}`, code_js_1.Code.InvalidArgument);
            }
        },
        serialize(data) {
            try {
                return data.toBinary(options);
            }
            catch (e) {
                const m = e instanceof Error ? e.message : String(e);
                throw new connect_error_js_1.ConnectError(`serialize binary: ${m}`, code_js_1.Code.Internal);
            }
        },
    };
}
exports.createBinarySerialization = createBinarySerialization;
/**
 * Creates a Serialization object for serializing the given protobuf message
 * with the protobuf canonical JSON encoding.
 *
 * By default, unknown fields are ignored.
 */
function createJsonSerialization(messageType, options) {
    var _a, _b;
    const textEncoder = (_a = options === null || options === void 0 ? void 0 : options.textEncoder) !== null && _a !== void 0 ? _a : new TextEncoder();
    const textDecoder = (_b = options === null || options === void 0 ? void 0 : options.textDecoder) !== null && _b !== void 0 ? _b : new TextDecoder();
    const o = getJsonOptions(options);
    return {
        parse(data) {
            try {
                const json = textDecoder.decode(data);
                return messageType.fromJsonString(json, o);
            }
            catch (e) {
                throw connect_error_js_1.ConnectError.from(e, code_js_1.Code.InvalidArgument);
            }
        },
        serialize(data) {
            try {
                const json = data.toJsonString(o);
                return textEncoder.encode(json);
            }
            catch (e) {
                throw connect_error_js_1.ConnectError.from(e, code_js_1.Code.Internal);
            }
        },
    };
}
exports.createJsonSerialization = createJsonSerialization;
