// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file v1/restic.proto (package v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * ResticSnapshot represents a restic snapshot.
 *
 * @generated from message v1.ResticSnapshot
 */
export class ResticSnapshot extends Message<ResticSnapshot> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int64 unix_time_ms = 2;
   */
  unixTimeMs = protoInt64.zero;

  /**
   * @generated from field: string hostname = 3;
   */
  hostname = "";

  /**
   * @generated from field: string username = 4;
   */
  username = "";

  /**
   * tree hash
   *
   * @generated from field: string tree = 5;
   */
  tree = "";

  /**
   * parent snapshot's id
   *
   * @generated from field: string parent = 6;
   */
  parent = "";

  /**
   * @generated from field: repeated string paths = 7;
   */
  paths: string[] = [];

  /**
   * @generated from field: repeated string tags = 8;
   */
  tags: string[] = [];

  constructor(data?: PartialMessage<ResticSnapshot>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "v1.ResticSnapshot";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "unix_time_ms", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "hostname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "tree", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "paths", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResticSnapshot {
    return new ResticSnapshot().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResticSnapshot {
    return new ResticSnapshot().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResticSnapshot {
    return new ResticSnapshot().fromJsonString(jsonString, options);
  }

  static equals(a: ResticSnapshot | PlainMessage<ResticSnapshot> | undefined, b: ResticSnapshot | PlainMessage<ResticSnapshot> | undefined): boolean {
    return proto3.util.equals(ResticSnapshot, a, b);
  }
}

/**
 * ResticSnapshotList represents a list of restic snapshots.
 *
 * @generated from message v1.ResticSnapshotList
 */
export class ResticSnapshotList extends Message<ResticSnapshotList> {
  /**
   * @generated from field: repeated v1.ResticSnapshot snapshots = 1;
   */
  snapshots: ResticSnapshot[] = [];

  constructor(data?: PartialMessage<ResticSnapshotList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "v1.ResticSnapshotList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "snapshots", kind: "message", T: ResticSnapshot, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResticSnapshotList {
    return new ResticSnapshotList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResticSnapshotList {
    return new ResticSnapshotList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResticSnapshotList {
    return new ResticSnapshotList().fromJsonString(jsonString, options);
  }

  static equals(a: ResticSnapshotList | PlainMessage<ResticSnapshotList> | undefined, b: ResticSnapshotList | PlainMessage<ResticSnapshotList> | undefined): boolean {
    return proto3.util.equals(ResticSnapshotList, a, b);
  }
}

/**
 * BackupProgressEntriy represents a single entry in the backup progress stream.
 *
 * @generated from message v1.BackupProgressEntry
 */
export class BackupProgressEntry extends Message<BackupProgressEntry> {
  /**
   * @generated from oneof v1.BackupProgressEntry.entry
   */
  entry: {
    /**
     * @generated from field: v1.BackupProgressStatusEntry status = 1;
     */
    value: BackupProgressStatusEntry;
    case: "status";
  } | {
    /**
     * @generated from field: v1.BackupProgressSummary summary = 2;
     */
    value: BackupProgressSummary;
    case: "summary";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<BackupProgressEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "v1.BackupProgressEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "message", T: BackupProgressStatusEntry, oneof: "entry" },
    { no: 2, name: "summary", kind: "message", T: BackupProgressSummary, oneof: "entry" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BackupProgressEntry {
    return new BackupProgressEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BackupProgressEntry {
    return new BackupProgressEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BackupProgressEntry {
    return new BackupProgressEntry().fromJsonString(jsonString, options);
  }

  static equals(a: BackupProgressEntry | PlainMessage<BackupProgressEntry> | undefined, b: BackupProgressEntry | PlainMessage<BackupProgressEntry> | undefined): boolean {
    return proto3.util.equals(BackupProgressEntry, a, b);
  }
}

/**
 * BackupProgressStatusEntry represents a single status entry in the backup progress stream.
 *
 * @generated from message v1.BackupProgressStatusEntry
 */
export class BackupProgressStatusEntry extends Message<BackupProgressStatusEntry> {
  /**
   * See https://restic.readthedocs.io/en/stable/075_scripting.html#id1
   *
   * 0.0 - 1.0
   *
   * @generated from field: double percent_done = 1;
   */
  percentDone = 0;

  /**
   * @generated from field: int64 total_files = 2;
   */
  totalFiles = protoInt64.zero;

  /**
   * @generated from field: int64 total_bytes = 3;
   */
  totalBytes = protoInt64.zero;

  /**
   * @generated from field: int64 files_done = 4;
   */
  filesDone = protoInt64.zero;

  /**
   * @generated from field: int64 bytes_done = 5;
   */
  bytesDone = protoInt64.zero;

  /**
   * @generated from field: repeated string current_file = 6;
   */
  currentFile: string[] = [];

  constructor(data?: PartialMessage<BackupProgressStatusEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "v1.BackupProgressStatusEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "percent_done", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "total_files", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "total_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "files_done", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "bytes_done", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "current_file", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BackupProgressStatusEntry {
    return new BackupProgressStatusEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BackupProgressStatusEntry {
    return new BackupProgressStatusEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BackupProgressStatusEntry {
    return new BackupProgressStatusEntry().fromJsonString(jsonString, options);
  }

  static equals(a: BackupProgressStatusEntry | PlainMessage<BackupProgressStatusEntry> | undefined, b: BackupProgressStatusEntry | PlainMessage<BackupProgressStatusEntry> | undefined): boolean {
    return proto3.util.equals(BackupProgressStatusEntry, a, b);
  }
}

/**
 * BackupProgressSummary represents a the summary event emitted at the end of a backup stream.
 *
 * @generated from message v1.BackupProgressSummary
 */
export class BackupProgressSummary extends Message<BackupProgressSummary> {
  /**
   * See https://restic.readthedocs.io/en/stable/075_scripting.html#summary
   *
   * @generated from field: int64 files_new = 1;
   */
  filesNew = protoInt64.zero;

  /**
   * @generated from field: int64 files_changed = 2;
   */
  filesChanged = protoInt64.zero;

  /**
   * @generated from field: int64 files_unmodified = 3;
   */
  filesUnmodified = protoInt64.zero;

  /**
   * @generated from field: int64 dirs_new = 4;
   */
  dirsNew = protoInt64.zero;

  /**
   * @generated from field: int64 dirs_changed = 5;
   */
  dirsChanged = protoInt64.zero;

  /**
   * @generated from field: int64 dirs_unmodified = 6;
   */
  dirsUnmodified = protoInt64.zero;

  /**
   * @generated from field: int64 data_blobs = 7;
   */
  dataBlobs = protoInt64.zero;

  /**
   * @generated from field: int64 tree_blobs = 8;
   */
  treeBlobs = protoInt64.zero;

  /**
   * @generated from field: int64 data_added = 9;
   */
  dataAdded = protoInt64.zero;

  /**
   * @generated from field: int64 total_files_processed = 10;
   */
  totalFilesProcessed = protoInt64.zero;

  /**
   * @generated from field: int64 total_bytes_processed = 11;
   */
  totalBytesProcessed = protoInt64.zero;

  /**
   * @generated from field: double total_duration = 12;
   */
  totalDuration = 0;

  /**
   * @generated from field: string snapshot_id = 13;
   */
  snapshotId = "";

  constructor(data?: PartialMessage<BackupProgressSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "v1.BackupProgressSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "files_new", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "files_changed", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "files_unmodified", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "dirs_new", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "dirs_changed", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "dirs_unmodified", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "data_blobs", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "tree_blobs", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "data_added", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "total_files_processed", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "total_bytes_processed", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "total_duration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "snapshot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BackupProgressSummary {
    return new BackupProgressSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BackupProgressSummary {
    return new BackupProgressSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BackupProgressSummary {
    return new BackupProgressSummary().fromJsonString(jsonString, options);
  }

  static equals(a: BackupProgressSummary | PlainMessage<BackupProgressSummary> | undefined, b: BackupProgressSummary | PlainMessage<BackupProgressSummary> | undefined): boolean {
    return proto3.util.equals(BackupProgressSummary, a, b);
  }
}

/**
 * @generated from message v1.BackupProgressError
 */
export class BackupProgressError extends Message<BackupProgressError> {
  /**
   * See https://restic.readthedocs.io/en/stable/075_scripting.html#error
   *
   * @generated from field: string item = 1;
   */
  item = "";

  /**
   * @generated from field: string during = 2;
   */
  during = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<BackupProgressError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "v1.BackupProgressError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "item", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "during", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BackupProgressError {
    return new BackupProgressError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BackupProgressError {
    return new BackupProgressError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BackupProgressError {
    return new BackupProgressError().fromJsonString(jsonString, options);
  }

  static equals(a: BackupProgressError | PlainMessage<BackupProgressError> | undefined, b: BackupProgressError | PlainMessage<BackupProgressError> | undefined): boolean {
    return proto3.util.equals(BackupProgressError, a, b);
  }
}

/**
 * RestoreProgressEvent represents a single entry in the restore progress stream.
 *
 * @generated from message v1.RestoreProgressEntry
 */
export class RestoreProgressEntry extends Message<RestoreProgressEntry> {
  /**
   * "summary" or "status"
   *
   * @generated from field: string message_type = 1;
   */
  messageType = "";

  /**
   * @generated from field: double seconds_elapsed = 2;
   */
  secondsElapsed = 0;

  /**
   * @generated from field: int64 total_bytes = 3;
   */
  totalBytes = protoInt64.zero;

  /**
   * @generated from field: int64 bytes_restored = 4;
   */
  bytesRestored = protoInt64.zero;

  /**
   * @generated from field: int64 total_files = 5;
   */
  totalFiles = protoInt64.zero;

  /**
   * @generated from field: int64 files_restored = 6;
   */
  filesRestored = protoInt64.zero;

  /**
   * 0.0 - 1.0
   *
   * @generated from field: double percent_done = 7;
   */
  percentDone = 0;

  constructor(data?: PartialMessage<RestoreProgressEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "v1.RestoreProgressEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "message_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "seconds_elapsed", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "total_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "bytes_restored", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "total_files", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "files_restored", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "percent_done", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RestoreProgressEntry {
    return new RestoreProgressEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RestoreProgressEntry {
    return new RestoreProgressEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RestoreProgressEntry {
    return new RestoreProgressEntry().fromJsonString(jsonString, options);
  }

  static equals(a: RestoreProgressEntry | PlainMessage<RestoreProgressEntry> | undefined, b: RestoreProgressEntry | PlainMessage<RestoreProgressEntry> | undefined): boolean {
    return proto3.util.equals(RestoreProgressEntry, a, b);
  }
}

/**
 * @generated from message v1.RepoStats
 */
export class RepoStats extends Message<RepoStats> {
  /**
   * @generated from field: int64 total_size = 1;
   */
  totalSize = protoInt64.zero;

  /**
   * @generated from field: int64 total_uncompressed_size = 2;
   */
  totalUncompressedSize = protoInt64.zero;

  /**
   * @generated from field: double compression_ratio = 3;
   */
  compressionRatio = 0;

  /**
   * @generated from field: int64 total_blob_count = 5;
   */
  totalBlobCount = protoInt64.zero;

  /**
   * @generated from field: int64 snapshot_count = 6;
   */
  snapshotCount = protoInt64.zero;

  constructor(data?: PartialMessage<RepoStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "v1.RepoStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total_uncompressed_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "compression_ratio", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "total_blob_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "snapshot_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RepoStats {
    return new RepoStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RepoStats {
    return new RepoStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RepoStats {
    return new RepoStats().fromJsonString(jsonString, options);
  }

  static equals(a: RepoStats | PlainMessage<RepoStats> | undefined, b: RepoStats | PlainMessage<RepoStats> | undefined): boolean {
    return proto3.util.equals(RepoStats, a, b);
  }
}

